import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowRight } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import c from 'classnames';

import FilterContent from './FilterContent';
import { getStaticText } from '../../utils/constants';
import CloseIcon from '../../assets/Close.svg';
import AuthStore from '../../common/AuthStore';

import './index.scss';

function FilterBar({
    size,
    surveyRespondents,
    surveys,
    closeFilters,
    filters,
    setFilters,
    projectInFilter,
    activeResetFilter,
    showBenchmarks,
    defaultBenchmark,
    currentView,
    defaultSettings,
    isInclusion,
    updateReportApiParams,
    reportApiParams,
    demographicList,
    isIaResurvey,
    benchmarkVersions,
    setBmVersionInUse,
    bmVersionInUse,
    isEEpage,
    years,
}) {
    const { lang, benchmark_year: benchYear = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const [activeProject, updateCurrentFilter] = useState(0);
    const [stateFilters, updateFilters] = useState(filters);
    const [benchmarkSelected, setBenchMark] = useState(false);
    const [demoSelected, setDemoActive] = useState(false);
    const { FILTER_FOR, REPORT_FILTER, COMPARISON_FILTER } = SITE_TEXT || {};
    const defaultBench = defaultBenchmark[0] || '';
    const defaultCode = parseInt(defaultBench.split(':')[1], 10);
    const defaultChildCode = parseInt(defaultBench.split(':')[0], 10);

    const practiceBuName = AuthStore.practiceBU || 'Comparison Group';
    const practiceReportName = AuthStore.practiceReportBU || 'Main Group';

    const resurveyReportName1 = AuthStore.resurveyFilter1 || 'Organization';
    const resurveyReportName2 = AuthStore.resurveyFilter2 || 'Comparison survey';
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const projectToUse = projectInFilter.length ? null : 0;
        updateCurrentFilter(projectToUse);
        // eslint-disable-next-line
    }, []);

    const updateFilterState = (index, value, updateRedux, activeFilter = true) => {
        const newList = [...stateFilters.slice(0, index), value, ...stateFilters.slice(index + 1)];
        updateFilters(newList);
        if (updateRedux) {
            setFilters(newList, true, activeFilter, index);
        }
    };

    const resetFilters = () => {
        const newFilters = stateFilters.map(({ demographics = [], benchmarks = [] }) => {
            const updatedDemographics = cloneDeep(demographics);
            const openItem = updatedDemographics.findIndex(({ isOpen: oldOpen }) => oldOpen);
            if (openItem !== -1) {
                updatedDemographics[openItem].isOpen = false;
            }
            updatedDemographics.forEach(({ options = [] }, indexToUse) => {
                const selectedLength = options.filter(({ isSelected }) => isSelected).length;
                if (selectedLength) {
                    updatedDemographics[indexToUse].options = options.map(oldOption => ({
                        ...oldOption,
                        isSelected: false,
                    }));
                }
            });
            const updatedBenchmarks = cloneDeep(benchmarks);
            if (benchmarks.length) {
                const indexToUse = updatedBenchmarks.findIndex(({ code }) => code === defaultCode);
                const indexToReset = updatedBenchmarks.findIndex(({ selectedOption }) => selectedOption);
                if (indexToReset !== -1) {
                    updatedBenchmarks[indexToReset].selectedOption = '';
                }
                if (indexToUse !== -1) {
                    const { options = [] } = updatedBenchmarks[indexToUse];
                    const childIndexToUse = options.findIndex(({ code }) => code === defaultChildCode);
                    const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
                    updatedBenchmarks[indexToUse].selectedOption =
                        updatedBenchmarks[indexToUse].options[selectedChildIndex].code;
                }
                const openedBenchmark = updatedBenchmarks.findIndex(({ isOpen: oldOpen }) => oldOpen);
                if (openedBenchmark !== -1) {
                    updatedBenchmarks[openedBenchmark].isOpen = false;
                }
            }
            return {
                demographics: updatedDemographics,
                benchmarks: updatedBenchmarks,
            };
        });
        setFilters(newFilters, false);
    };

    const activeApplyButton = demoSelected || benchmarkSelected;
    const showResurveyEdit1 = projectInFilter[0] && projectInFilter.length > 0;
    const showResurveyEdit2 = projectInFilter[1] && projectInFilter.length > 0;

    const showFilterBu1 = projectInFilter[0] === REPORT_FILTER && projectInFilter[1] === COMPARISON_FILTER;

    const handleClose = () => {
        setTimeout(() => {
            closeFilters(false);
        }, 200);
        setIsOpen(false);
    };
    return (
        <div className="filterDialog" data-testid="filterDialog">
            <div className="backdropRoot" onClick={handleClose} />
            <div className={`rightSideFilters ${isOpen ? 'slideIn' : 'slideOut'}`}>
                {projectInFilter.length && activeProject === null ? (
                    <div className="projectsList">
                        <div className="topContent clearfix">
                            <h2>{FILTER_FOR}</h2>
                            <div className="closeContent">
                                <img
                                    data-testid="closeFilters"
                                    src={CloseIcon}
                                    alt=""
                                    onClick={() => closeFilters(false)}
                                />
                            </div>
                        </div>
                        <ul className="selectList">
                            {projectInFilter.map((ohId, index) => {
                                const { demographics = [], benchmarks = [] } = stateFilters[index];
                                const demoSelLength = demographics.filter(
                                    ({ options = [] }) => options.filter(({ isSelected }) => isSelected).length
                                ).length;
                                const { code = '' } =
                                    benchmarks.filter(({ selectedOption }) => selectedOption)[0] || {};

                                const filtersApplied = demoSelLength || (code && code !== defaultCode);
                                return (
                                    <>
                                        <li
                                            data-testid={ohId}
                                            className={c({ filtersApplied })}
                                            key={index}
                                            onClick={() => updateCurrentFilter(index)}
                                        >
                                            {ohId}
                                            <KeyboardArrowRight />
                                        </li>

                                        <div className="projectBuFilter">
                                            {showFilterBu1 && (
                                                <div>
                                                    {projectInFilter[0] === REPORT_FILTER && index === 0 && (
                                                        <span className="buOrgFilter">{practiceReportName}</span>
                                                    )}
                                                    {projectInFilter[1] === COMPARISON_FILTER && index === 1 && (
                                                        <span className="buOrgFilter">{practiceBuName}</span>
                                                    )}
                                                </div>
                                            )}
                                            {!showFilterBu1 && (
                                                <div>
                                                    {showResurveyEdit1 && index === 0 && (
                                                        <span className="buOrgFilter">{resurveyReportName1}</span>
                                                    )}
                                                    {showResurveyEdit2 && index === 1 && (
                                                        <span className="buOrgFilter">{resurveyReportName2}</span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                );
                            })}
                        </ul>
                    </div>
                ) : null}
                {activeProject !== null || !projectInFilter.length ? (
                    <FilterContent
                        size={projectInFilter.length ? size[activeProject] : size[0]}
                        surveyRespondents={
                            projectInFilter.length ? surveyRespondents[activeProject] : surveyRespondents[0]
                        }
                        surveys={projectInFilter.length ? surveys[activeProject] : surveys[0]}
                        indexToUpdate={activeProject}
                        filters={stateFilters[activeProject]}
                        activeApplyButton={activeApplyButton}
                        activeResetFilter={activeResetFilter}
                        setBenchMark={setBenchMark}
                        setDemoActive={setDemoActive}
                        updateFilterState={updateFilterState}
                        updateCurrentFilter={updateCurrentFilter}
                        closeFilters={closeFilters}
                        showHeader={projectInFilter.length ? projectInFilter[activeProject] : ''}
                        resetFilters={resetFilters}
                        showBenchmarks={showBenchmarks}
                        currentView={currentView}
                        activeProject={activeProject}
                        defaultSettings={defaultSettings}
                        isInclusion={isInclusion}
                        updateReportApiParams={updateReportApiParams}
                        reportApiParams={reportApiParams}
                        demographicList={demographicList}
                        isIaResurvey={isIaResurvey}
                        benchmarkVersions={benchmarkVersions}
                        bmVersionInUse={bmVersionInUse}
                        setBmVersionInUse={setBmVersionInUse}
                        isEEpage={isEEpage}
                        showResurvey={projectInFilter.length ? projectInFilter[activeProject] : ''}
                        showRes={projectInFilter.length ? projectInFilter : ''}
                        year={years.length ? years[activeProject] : benchYear}
                        setIsOpen={setIsOpen}
                    />
                ) : null}
            </div>
        </div>
    );
}

FilterBar.defaultProps = {
    projectInFilter: [],
    showBenchmarks: true,
    currentView: 0,
    isInclusion: false,
    reportApiParams: {},
    updateReportApiParams: () => {},
    demographicList: [],
    isIaResurvey: false,
    benchmarkVersions: [],
    bmVersionInUse: 2,
    setBmVersionInUse: () => {},
    isEEpage: false,
    years: [],
};

FilterBar.propTypes = {
    projectInFilter: PropTypes.array,
    showBenchmarks: PropTypes.bool,
    currentView: PropTypes.number,
    activeResetFilter: PropTypes.bool.isRequired,
    size: PropTypes.array.isRequired,
    surveyRespondents: PropTypes.array.isRequired,
    surveys: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    defaultBenchmark: PropTypes.array.isRequired,
    closeFilters: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isInclusion: PropTypes.bool,
    reportApiParams: PropTypes.object,
    updateReportApiParams: PropTypes.func,
    demographicList: PropTypes.array,
    isIaResurvey: PropTypes.bool,
    benchmarkVersions: PropTypes.array,
    bmVersionInUse: PropTypes.number,
    setBmVersionInUse: PropTypes.func,
    isEEpage: PropTypes.bool,
    years: PropTypes.array,
};

export default FilterBar;
