import React, { useState, useEffect } from 'react';
import moment from 'moment';
import c from 'classnames';
import { Accordion, AccordionDetails, Tooltip, Select, MenuItem, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
// import * as XLSX from 'xlsx';
import XLSX from 'xlsx-color';
import { KeyboardArrowDownSharp } from '@material-ui/icons';
import './index.scss';
import { USNumFormat } from '../../../utils/functions';
import CloseIcon from '../../../assets/Close.svg';
import ArrowBackward from '../../Diagnose/assets/Small-left.svg';
import ArrowForward from '../../Diagnose/assets/Small-right.svg';
import ArrowDoubleBack from '../../Diagnose/assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../../Diagnose/assets/Double-arrow-right.svg';
import Refresh from '../../Reports/assets/Refresh.svg';
import ConfirmModal from './ConfirmModal';
import searchBar from '../../UserReports/assets/zoom.svg';
// import Down from '../../UserReports/assets/D'
import Down from '../../Diagnose/assets/Down.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import CloudImage from '../assets/InsufficientData1.svg';
import ArrowRight from '../../UserReports/assets/arrowRight.svg';

const dummyData = [
    { keyName: 'total_survey', value: 'Total surveys' },
    { keyName: 'total_responded', value: 'Total respondents' },
    { keyName: 'approve_survey', value: 'Approved surveys' },
    { keyName: 'approve_responded', value: 'Approved respondents' },
];

const CREATE_CUSTOM_BM = [
    // {
    //     key: 'selectid',
    //     title: '',
    //     width: '4vw',
    // },
    {
        key: 'action',
        title: 'Actionable',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'benchmark_status',
        title: 'Benchmark Status',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'uniquesurveyreferencecode',
        title: 'OHI ID',
        width: '8vw',
        cursor: 'pointer',
    },
    {
        key: 'confirmitsurveyid',
        title: 'PID',
        width: '12vw',
        cursor: 'pointer',
    },
    {
        key: 'surveyclientname',
        title: 'Organization Name',
        width: '20vw',
        cursor: 'pointer',
    },
    {
        key: 'resurveyid',
        title: 'Resurvey ID',
        width: '8vw',
        cursor: 'pointer',
    },
    {
        key: 'isgsdbfilled',
        title: 'GSOD Filled',
        width: '6vw',
        cursor: 'pointer',
    },
    {
        key: 'legalcheck',
        title: 'Legal Check',
        width: '6vw',
        cursor: 'pointer',
    },
    {
        key: 'isresurvey',
        title: 'Resurvey',
        width: '7vw',
        cursor: 'pointer',
    },
    {
        key: 'type',
        title: 'Type',
        width: '7vw',
        cursor: 'pointer',
    },
    {
        key: 'benchmarkingallowed',
        title: 'Benchmarking Allowed',
        width: '7vw',
        cursor: 'pointer',
    },
    {
        key: 'totalcomplete',
        title: 'Complete Responses',
        width: '6vw',
        align: 'right',
        cursor: 'pointer',
    },
    {
        key: 'bm_year',
        title: 'Benchmarking Year',
        width: '8vw',
        align: 'center',
        cursor: 'pointer',
    },
    {
        key: 'quartile',
        title: 'Global Quartile',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'mckinseysectorlevel1',
        title: 'Sector Level 1',
        width: '20vw',
        cursor: 'pointer',
    },
    {
        key: 'mckinseysectorlevel2',
        title: 'Sector Level 2',
        width: '20vw',
        cursor: 'pointer',
    },
    {
        key: 'region',
        title: 'Region',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'subregion1',
        title: 'Subregion (high level)',
        width: '15vw',
        cursor: 'pointer',
    },
    {
        key: 'subregion2',
        title: 'Subregion (detailed)',
        width: '15vw',
        cursor: 'pointer',
    },
    {
        key: 'country',
        title: 'Country',
        width: '15vw',
        cursor: 'pointer',
    },
    {
        key: 'close_date',
        title: 'Closed Date',
        width: '10vw',
        cursor: 'pointer',
    },
];

const RealtimeDatabase = ({
    defaultRealTimeYear,
    realtimeTotalData,
    realtimeSurveyList,
    getRealtimeApproval,
    getRealtimeReject,
    getRealtimeSurveyList,
    // hideSecondPopup,
    secondPopupVisible,
    getRealtimeAnalytics,
    // new
    realtimeToggleFilterView,
    renderRealtimeFilterApplied,
    refreshMetaRequest,
    refreshMetaData,
    // renderFilterApplied,
    realtimeFiltersApplied,
}) => {
    const [popupContent, setPopupContent] = useState(false);
    const [realtimeId, setRealtimeId] = useState();
    const [showPopupBtn, setShowPopupBtn] = useState('');
    const [showRefreshModal, setRefreshModal] = useState(false);
    const [showRefreshModalMsg, setRefreshModalMsg] = useState(false);
    const [refreshItem, setRefreshItem] = useState({ id: '', bm_year: '', msg: '' });
    // const [page, setPage] = useState(1);
    // const [min, setMinSlice] = useState(0);
    // const [max, setMaxSlice] = useState(5);
    // const [entryCount, setEntryCount] = useState(10);
    const { success = [], failed = [] } = refreshMetaData || {};
    const refreshData = success.length ? success : failed;
    const { detail = '' } = refreshData[0] || {};
    const { msg } = refreshItem || {};
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        if (secondPopupVisible) {
            if (defaultRealTimeYear) {
                getRealtimeAnalytics(`year:${defaultRealTimeYear}`);
            }
            if (defaultRealTimeYear) {
                getRealtimeSurveyList(`year:${defaultRealTimeYear}`);
            }
        }
        // eslint-disable-next-line
    }, [defaultRealTimeYear, secondPopupVisible]);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            color: '#fff',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            maxWidth: 'none',
            width: '400px',
            whiteSpace: 'normal',
            padding: '10px',
            borderRadius: '4px',
        },
    }))(Tooltip);

    const processSurveyList = list => {
        return list.map(item => {
            let action = '';
            const actionable = '';

            switch (item.benchmark_status) {
                case 'pending':
                case 'approved':
                case 'rejected':
                    action = (
                        <>
                            <div
                                style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <div className="explore">TAKE ACTION</div>
                                <img src={ArrowRight} alt="" />
                            </div>
                        </>
                    );
                    break;
                default:
                    action = '';
            }
            return { ...item, action, actionable };
        });
    };

    const getStatusStyles = status => {
        switch (status) {
            case 'pending':
                return { backgroundColor: '#fff9d7', color: '#d19b3e', padding: '6px', borderRadius: '8px' };
            case 'approved':
                return { backgroundColor: '#defae6', color: '#027f26', padding: '6px', borderRadius: '8px' };
            case 'rejected':
                return { backgroundColor: '#fde7e7', color: '#d72c3b', padding: '6px', borderRadius: '8px' };
            default:
                return { backgroundColor: 'white', color: 'black', padding: '6px', borderRadius: '8px' };
        }
    };

    const processedSurveyList = processSurveyList(realtimeSurveyList);
    const [filteredData, setFilteredData] = useState(processedSurveyList) || [];
    const [page, setPage] = useState(1);
    const [min, setMinSlice] = useState(0);
    const [max, setMaxSlice] = useState(5);
    const [entryCount, setEntryCount] = useState(10);

    const lastPage = Math.ceil(filteredData.length / entryCount);

    const handleNextPage = () => {
        if (page < lastPage) {
            setPage(page + 1);
            if (page + 1 > max) {
                setMinSlice(min + 5);
                setMaxSlice(max + 5);
            }
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            if (page - 1 <= min) {
                setMinSlice(min - 5);
                setMaxSlice(max - 5);
            }
        }
    };

    const handleFirstPageSlice = () => {
        setPage(1);
        setMinSlice(0);
        setMaxSlice(5);
    };

    const handleLastPageSlice = () => {
        setPage(lastPage);
        const lastSliceStart = Math.floor((lastPage - 1) / 5) * 5;
        setMinSlice(lastSliceStart);
        setMaxSlice(lastSliceStart + 5);
    };

    const handleResultsPerPageChange = e => {
        setEntryCount(e.target.value);
        setPage(1);
        setMinSlice(0);
        setMaxSlice(5);
    };

    // useEffect(() => {
    //     setFilteredData(processSurveyList(processedSurveyList));
    // }, [processedSurveyList]);

    const normalizeString = str => {
        if (!str) return '';
        return str.toLowerCase().replace(/\s+/g, '');
    };

    const handleSearchChange = e => {
        const { value } = e.target;
        setSearchInput(value);
        const normalizedInput = normalizeString(value);

        const filtered = processedSurveyList.filter(
            ({ uniquesurveyreferencecode = '', confirmitsurveyid = '', surveyclientname = '' }) =>
                normalizeString(uniquesurveyreferencecode).includes(normalizedInput) ||
                normalizeString(confirmitsurveyid).includes(normalizedInput) ||
                normalizeString(surveyclientname).includes(normalizedInput)
        );
        setFilteredData(processSurveyList(filtered));
    };

    // const lastPage = Math.ceil(processedSurveyList.length / entryCount);
    // console.log('processedSurveyList', processedSurveyList);

    // const lastPage = Math.ceil(processedSurveyList.length / entryCount);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(realtimeSurveyList);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Survey Data');
        XLSX.writeFile(workbook, 'SurveyData.xlsx');
    };

    const handleCellClick = (id, key) => {
        setShowPopupBtn(key);
        setRealtimeId(id);
        setPopupContent(true);
    };

    const closePopup = () => {
        setPopupContent(false);
    };

    const handleApprove = () => {
        getRealtimeApproval({
            year: defaultRealTimeYear,
            id: [realtimeId],
        });
        closePopup();
    };

    const handleReject = () => {
        getRealtimeReject({
            year: defaultRealTimeYear,
            id: [realtimeId],
        });
        closePopup();
    };

    const handleRefreshBtnClick = (id, year) => {
        const { uniquesurveyreferencecode } = processedSurveyList.find(({ id: s_id }) => s_id === id);
        setRefreshModal(true);
        setRefreshItem({
            id,
            bm_year: year,
            msg: `Do you want to refresh the meta information and score for ${uniquesurveyreferencecode} (${year}). Refreshing the OHID will update the meta information and score`,
        });
    };

    const handleRefreshAction = () => {
        const { id, bm_year } = refreshItem;
        refreshMetaRequest({ id: [id], year: bm_year });
        setRefreshModal(false);
    };

    const handleClose = () => {
        setRefreshModal(false);
    };

    // const handleRefresh = () => {
    //     if (defaultRealTimeYear) {
    //         getRealtimeAnalytics(`year:${defaultRealTimeYear}`);
    //     }
    //     if (defaultRealTimeYear) {
    //         getRealtimeSurveyList(`year:${defaultRealTimeYear}`);
    //     }
    //     hideSecondPopup();
    // };
    useEffect(() => {
        if (refreshData.length) {
            setRefreshModalMsg(true);
        }
        // eslint-disable-next-line
    }, [refreshMetaData]);
    return (
        <div className="realTimeData">
            <div className="realtimeMainHead">
                <div className="realTimeHead">
                    <div className="realTimeHeading">Realtime Database</div>
                    <div className="nRes">{`${'WIP Year'} : ${defaultRealTimeYear}`}</div>
                </div>

                <ul className="navScoreList">
                    <li data-testid="getPptBtn" onClick={downloadExcel}>
                        <img src={Down} alt="" />
                        <span className="lineHead upperHead">Download</span>
                    </li>
                    <li onClick={() => realtimeToggleFilterView(true)} data-testid="filterButton">
                        <img src={!realtimeFiltersApplied ? FilterSvg : ActiveFilter} alt="" />
                        <span className="filter">Filter</span>
                    </li>
                </ul>
            </div>
            <div className="realtimeFilter">{renderRealtimeFilterApplied}</div>
            <div className="realtimeSection">
                <div className="cardContainer">
                    {Object.keys(realtimeTotalData)
                        .slice(0, 2)
                        .map(key => {
                            const realTimeData = dummyData.slice(0, 2).find(item => item.keyName === key).value;
                            return (
                                <div className="cardContent" key={realtimeTotalData[key]}>
                                    <div className="cardHead">{realTimeData}</div>
                                    <div className="cardSubHead">{realtimeTotalData[key].toLocaleString('en-US')}</div>
                                </div>
                            );
                        })}
                </div>

                <div className="cardContainer">
                    {Object.keys(realtimeTotalData)
                        .slice(2, 4)
                        .map(key => {
                            const realTimeData1 = dummyData.slice(2, 4).find(item => item.keyName === key).value;
                            return (
                                <div className="cardContent" key={realtimeTotalData[key]}>
                                    <div className="cardHead">{realTimeData1}</div>
                                    <div className="cardSubHead">{realtimeTotalData[key].toLocaleString('en-US')}</div>
                                </div>
                            );
                        })}
                </div>
            </div>

            {/* new  */}
            {/* <div>
                <div>
                    <div>Survey List</div>
                </div>

                <div className="searchBar">
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={handleSearchChange}
                            className="search"
                        />
                        <div className="searchIcon">
                            <img src={searchBar} alt="" />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="realtimeSearchContent">
                <div className="realtimeHeading">Survey List</div>

                <div className="searchBar">
                    <input
                        type="text"
                        // placeholder="Search"
                        placeholder="Search by OHI ID, PID, Organization Name"
                        value={searchInput}
                        onChange={handleSearchChange}
                        className="search"
                    />
                    <div className="searchIcon">
                        <img src={searchBar} alt="" />
                    </div>
                </div>
            </div>

            <div className="userReportsContent xScroll">
                <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <table className="userSection">
                            <tbody className="userContent">
                                <thead className="headerSection">
                                    <th />
                                    {CREATE_CUSTOM_BM.map(({ key, title, width }) => (
                                        <th className="headerHeading" key={key} style={{ width }}>
                                            {title}
                                        </th>
                                    ))}
                                </thead>

                                {!!filteredData.length &&
                                    filteredData.slice((page - 1) * entryCount, page * entryCount).map(obj => {
                                        const {
                                            id = '',
                                            action = '',
                                            benchmark_status,
                                            bm_year,
                                            is_allow_meta_update,
                                        } = obj || {};
                                        return (
                                            <>
                                                <tr key={id} className="userDataSection">
                                                    {is_allow_meta_update ? (
                                                        <LightTooltip
                                                            title="The survey details are refreshed for the current year surveys only (till its status is pending). Details of surveys which are part of approved benchmarks are not refreshed."
                                                            arrow
                                                        >
                                                            <td
                                                                onClick={() => handleRefreshBtnClick(id, bm_year)}
                                                                className="refreshBtn"
                                                            >
                                                                <img src={Refresh} alt="" />
                                                            </td>
                                                        </LightTooltip>
                                                    ) : (
                                                        <LightTooltip
                                                            title="Meta update is not allowed for this survey."
                                                            arrow
                                                        >
                                                            <td className="refreshBtn disabled">
                                                                <img src={Refresh} alt="" style={{ opacity: 0.5 }} />
                                                            </td>
                                                        </LightTooltip>
                                                    )}
                                                    {CREATE_CUSTOM_BM.map(({ key, width, align = '', cursor }) => {
                                                        let dataValue =
                                                            key === 'close_date'
                                                                ? moment(obj[key]).format('MMM-YYYY')
                                                                : USNumFormat(obj[key]);
                                                        if (key === 'bm_year') {
                                                            dataValue = obj[key];
                                                        }
                                                        if (key === 'isgsdbfilled') {
                                                            dataValue = dataValue === '1' ? 'true' : 'false';
                                                        }
                                                        if (key === 'legalcheck') {
                                                            dataValue = dataValue === '1' ? 'true' : 'false';
                                                        }
                                                        const styles =
                                                            key === 'benchmark_status'
                                                                ? getStatusStyles(benchmark_status)
                                                                : {};
                                                        return (
                                                            <td
                                                                className="userTableContent ellipsisPro"
                                                                key={key}
                                                                // onClick={
                                                                //     key === 'action'
                                                                //         ? () => handleCellClick(id, action)
                                                                //         : null
                                                                // }
                                                                onClick={
                                                                    key === 'action'
                                                                        ? () => handleCellClick(id, benchmark_status)
                                                                        : null
                                                                }
                                                                style={{
                                                                    width,
                                                                    textAlign: align,
                                                                    cursor,
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={dataValue}
                                                                    arrow
                                                                    classes={{ popper: 'cbmTooltip' }}
                                                                >
                                                                    <span style={styles}>{dataValue}</span>
                                                                </Tooltip>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            </>
                                        );
                                    })}

                                {!filteredData.length ? (
                                    <div className="emptyList">
                                        <div>
                                            <h3>No survey list available</h3>
                                            <img src={CloudImage} alt="" />
                                        </div>
                                    </div>
                                ) : null}
                                {/* {surveyListLoading ? <LoaderToast /> : null}  */}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
                {!!filteredData.length && filteredData.length > entryCount ? (
                    <div className="pagination">
                        <span onClick={handleFirstPageSlice} className={c({ inActive: page === 1 }, 'arrowBtn')}>
                            <img src={ArrowDoubleBack} alt="First Page" />
                        </span>
                        <span onClick={handlePrevPage} className={c({ inActive: page === 1 }, 'arrowBtn')}>
                            <img src={ArrowBackward} alt="Previous Page" />
                        </span>

                        {Array(lastPage)
                            .fill(1)
                            .map((_, i) => i + 1)
                            .slice(min, max)
                            .map(index => (
                                <span
                                    key={index}
                                    className={c({ activePage: page === index }, 'paginationButton')}
                                    onClick={() => setPage(index)}
                                >
                                    {index}
                                </span>
                            ))}

                        <span onClick={handleNextPage} className={c({ inActive: page === lastPage }, 'arrowBtn')}>
                            <img src={ArrowForward} alt="Next Page" />
                        </span>
                        <span onClick={handleLastPageSlice} className={c({ inActive: page === lastPage }, 'arrowBtn')}>
                            <img src={ArrowDoubleForward} alt="Last Page" />
                        </span>

                        <div className="resultsPerPage">
                            <div className="resultHeading">Results Per Page</div>
                            <Select
                                IconComponent={KeyboardArrowDownSharp}
                                value={entryCount}
                                onChange={handleResultsPerPageChange}
                                MenuProps={{
                                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                                }}
                            >
                                {[10, 20, 30, 40, 50].map(pageItem => (
                                    <MenuItem key={pageItem} value={pageItem}>
                                        {pageItem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                ) : null}

                {popupContent && (
                    <div className="popup">
                        <div className="popupContent">
                            <div className="realtimeCloseIcon">
                                <img src={CloseIcon} alt="" className="closeIcon" onClick={closePopup} />
                            </div>
                            {showPopupBtn === 'pending' && (
                                <div>
                                    <h2>Do you want to approve or reject the survey ?</h2>
                                    <p>
                                        When you reject a survey, it is excluded from the Work-in-progress benchmark.
                                        You may approve it later, if required.
                                    </p>
                                    <p>
                                        When you approve a survey, it gets added to the Work-in-Progress benchmark. This
                                        will be reflected in the filter panel of BMX screens. You may reject it later,
                                        if required.
                                    </p>
                                </div>
                            )}

                            {showPopupBtn === 'approved' && (
                                <div>
                                    <h2>Do you want to reject the survey ?</h2>
                                    <p>
                                        When you reject a survey, it is excluded from the Work-in-progress benchmark.
                                        You may approve it later, if required.
                                    </p>
                                </div>
                            )}

                            {showPopupBtn === 'rejected' && (
                                <div>
                                    <h2>Do you want to approve the survey ?</h2>
                                    <p>
                                        When you approve a survey, it gets added to the Work-in-Progress benchmark. This
                                        will be reflected in the filter panel of BMX screens. You may reject it later,
                                        if required.
                                    </p>
                                </div>
                            )}

                            {showPopupBtn === 'pending' && (
                                <div className="realtimePopupButton">
                                    <div type="button" onClick={handleApprove} className="approveBtn">
                                        Approve
                                    </div>
                                    <div type="button" onClick={handleReject} className="rejectBtn">
                                        Reject
                                    </div>
                                </div>
                            )}
                            {showPopupBtn !== 'pending' && (
                                <div className="realtimePopupButton">
                                    {showPopupBtn === 'approved' && (
                                        <button type="button" onClick={handleReject} className="approveBtn">
                                            Reject
                                        </button>
                                    )}

                                    {showPopupBtn === 'rejected' && (
                                        <button type="button" onClick={handleApprove} className="approveBtn">
                                            Approve
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {showRefreshModal && (
                    <ConfirmModal handleAcceptAction={handleRefreshAction} closeAction={handleClose} message={msg} />
                )}
                {showRefreshModalMsg && refreshData.length && (
                    <ConfirmModal
                        handleAcceptAction={() => setRefreshModalMsg(false)}
                        closeAction={() => setRefreshModalMsg(false)}
                        message={detail}
                        success={success.length}
                        failed={failed.length}
                    />
                )}
            </div>
        </div>
    );
};

RealtimeDatabase.propTypes = {
    defaultRealTimeYear: PropTypes.number.isRequired,
    realtimeTotalData: PropTypes.array.isRequired,
    realtimeSurveyList: PropTypes.array.isRequired,
    getRealtimeApproval: PropTypes.func.isRequired,
    getRealtimeReject: PropTypes.func.isRequired,
    // hideSecondPopup: PropTypes.func.isRequired,
    secondPopupVisible: PropTypes.bool.isRequired,
    getRealtimeAnalytics: PropTypes.func.isRequired,
    getRealtimeSurveyList: PropTypes.func.isRequired,
    realtimeToggleFilterView: PropTypes.func.isRequired,
    renderRealtimeFilterApplied: PropTypes.string.isRequired,
    refreshMetaRequest: PropTypes.func.isRequired,
    refreshMetaData: PropTypes.func.isRequired,
    // renderFilterApplied: PropTypes.string.isRequired,
    realtimeFiltersApplied: PropTypes.bool.isRequired,
};

export default RealtimeDatabase;
