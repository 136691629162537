import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RealtimeDatabase from './RealtimeDatabase';
import { getRealtimeApproval, getRealtimeReject, hideSecondPopup, refreshMetaRequest } from '../actions';

import {
    selectRealtimeApproveList,
    selectRealtimeRefreshMeta,
    selectRealTimeRejectList,
    selectSecondPopupVisible,
} from '../selector';

const stateToProps = createStructuredSelector({
    realTimeApproveList: selectRealtimeApproveList(),
    realTimeRejectList: selectRealTimeRejectList(),
    secondPopupVisible: selectSecondPopupVisible(),
    refreshMetaData: selectRealtimeRefreshMeta(),
});

const dispatchToProps = {
    getRealtimeApproval,
    getRealtimeReject,
    hideSecondPopup,
    refreshMetaRequest,
};

export default connect(stateToProps, dispatchToProps)(RealtimeDatabase);
