import { createSelector } from 'reselect';

const analytics = state => state.get('analytics');

const selectAnalyticsApiCount = () => createSelector(analytics, state => state.toJS().count);
const selectDataAtGlance = () => createSelector(analytics, state => state.toJS().dataAtGlance);
const selectDataAtGlanceFetched = () => createSelector(analytics, state => state.toJS().dataAtGlanceFetched);
const selectDifferentialData = () => createSelector(analytics, state => state.toJS().differentialData);
const selectDifferentialDataFetched = () => createSelector(analytics, state => state.toJS().differentialDataFetched);
const selectRankingData = () => createSelector(analytics, state => state.toJS().rankingData);
const selectRankingFetched = () => createSelector(analytics, state => state.toJS().rankingFetched);
const selectAnalyticsPerCategory = () => createSelector(analytics, state => state.toJS().analyticsPerCategoryData);
const selectFlagpoleData = () => createSelector(analytics, state => state.toJS().flagpoleData);
const selectFlagpoleFetched = () => createSelector(analytics, state => state.toJS().flagpoleFetched);
const selectOutcomeData = () => createSelector(analytics, state => state.toJS().scorecardData);
const selectScorecardDataFetched = () => createSelector(analytics, state => state.toJS().scorecardDataFetched);
const selectFilters = () => createSelector(analytics, state => state.toJS().filters);
const selectFiltersFetched = () => createSelector(analytics, state => state.toJS().filtersFetched);
const selectFiltersApplied = () => createSelector(analytics, state => state.toJS().filtersApplied);
const selectScorecardDropdown = () => createSelector(analytics, state => state.toJS().scorecardDropdown);
const selectFilterParam = () => createSelector(analytics, state => state.toJS().filterParam);
const selectScorecardError = () => createSelector(analytics, state => state.toJS().scorecardError);
const selectFlagpoleError = () => createSelector(analytics, state => state.toJS().flagpoleError);
const selectDifferentialError = () => createSelector(analytics, state => state.toJS().differentialError);
const selectRankingError = () => createSelector(analytics, state => state.toJS().rankingError);
const selectFiltersMapping = () => createSelector(analytics, state => state.toJS().filtersMapping);
const selectFiltersMappingFetched = () => createSelector(analytics, state => state.toJS().filtersMappingFetched);
const selectDefaultFilters = () => createSelector(analytics, state => state.toJS().defaultFilters);
const selectDefaultYear = () => createSelector(analytics, state => state.toJS().defaultYear);
const selectRange = () => createSelector(analytics, state => state.toJS().range);
const selectMedianData = () => createSelector(analytics, state => state.toJS().medianData);
const selectMedianDataFetched = () => createSelector(analytics, state => state.toJS().medianDataFetched);
const selectShowQuartileBtn = () => createSelector(analytics, state => state.toJS().showQuartileBtn);
const selectShowMedianBtn = () => createSelector(analytics, state => state.toJS().showMedianBtn);
const selectMedianError = () => createSelector(analytics, state => state.toJS().medianError);
const selectCustomBmList = () => createSelector(analytics, state => state.toJS().customBmList);
const selectCustomBmListFetched = () => createSelector(analytics, state => state.toJS().customBmListFetched);
const selectCustomBmSurveyList = () => createSelector(analytics, state => state.toJS().customBmSurveyList);
const selectSaveCustomBmDraft = () => createSelector(analytics, state => state.toJS().saveCustomBmMsg);
const selectCustomBmDetail = () => createSelector(analytics, state => state.toJS().customBmDetail);
const selectCreateCustomBmFilter = () => createSelector(analytics, state => state.toJS().createCustomBmFilter);
const selectCreateCustomBmForm = () => createSelector(analytics, state => state.toJS().createCustomBmForm);
const selectCheckNameMsg = () => createSelector(analytics, state => state.toJS().checkNameMsg);
const selectIsNameAvailable = () => createSelector(analytics, state => state.toJS().isNameAvailable);
const selectSurveyListLoading = () => createSelector(analytics, state => state.toJS().surveyListLoading);
const selectCustomBmDefaultFilter = () => createSelector(analytics, state => state.toJS().customBmDefaultFilter);
const selectActionCode = () => createSelector(analytics, state => state.toJS().actionCode);
const selectApprovalRequestList = () => createSelector(analytics, state => state.toJS().approvalRequestList);
const selectApprovalRequestListFetched = () =>
    createSelector(analytics, state => state.toJS().approvalRequestListFetched);
const selectApprovalRejectList = () => createSelector(analytics, state => state.toJS().approvalRejectList);
const selectApprovalRejectListFetched = () =>
    createSelector(analytics, state => state.toJS().approvalRejectListFetched);
const selectApprovedList = () => createSelector(analytics, state => state.toJS().approvedBmList);
const selectApprovedListFetched = () => createSelector(analytics, state => state.toJS().approvedBmListFetched);
const selectEditCustomBmForm = () => createSelector(analytics, state => state.toJS().editCustomBmForm);
const selectBmExist = () => createSelector(analytics, state => state.toJS().bmExist);
const selectExistingBm = () => createSelector(analytics, state => state.toJS().existingBm);
const selectDefaultRealTimeYear = () => createSelector(analytics, state => state.toJS().defaultRealTimeYear);
const selectRealtimeTotalData = () => createSelector(analytics, state => state.toJS().realtimeTotalData);
const selectRealtimeSurveyList = () => createSelector(analytics, state => state.toJS().realtimeSurveyList);
const selectRealtimeApproveList = () => createSelector(analytics, state => state.toJS().realtimeApproveList);
const selectRealTimeApproveListFetched = () =>
    createSelector(analytics, state => state.toJS().realTimeAppproveListFeiched);
const selectRealTimeRejectList = () => createSelector(analytics, state => state.toJS().realTimeRejectList);
const selectRealTimeRejectListFetched = () =>
    createSelector(analytics, state => state.toJS().realTimeRejectedListFetched);
const selectSecondPopupVisible = () => createSelector(analytics, state => state.toJS().secondPopupVisible);
const selectRealtimeTotalDataFetched = () => createSelector(analytics, state => state.toJS().realtimeTotalDataFetched);
const selectRealtimeSurveyListFetched = () =>
    createSelector(analytics, state => state.toJS().realtimeSurveyListFetched);
const selectRealtimeFiltersApplied = () => createSelector(analytics, state => state.toJS().realtimeFiltersApplied);
const selectRealtimeFiltersData = () => createSelector(analytics, state => state.toJS().realtimeFiltersData);
const selectRealtimeDafaultFiltersData = () =>
    createSelector(analytics, state => state.toJS().realtimeDefaultFiltersData);
const selectRealtimeFiltersParams = () => createSelector(analytics, state => state.toJS().realtimeFiltersParams);
const selectRealtimeFilterDataFetched = () =>
    createSelector(analytics, state => state.toJS().realtimeFilterDataFetched);
const selectRealtimeRefreshMeta = () => createSelector(analytics, state => state.toJS().refreshMetaData);

export {
    selectAnalyticsApiCount,
    selectDataAtGlance,
    selectFilters,
    selectFiltersFetched,
    selectFiltersApplied,
    selectDataAtGlanceFetched,
    selectDifferentialData,
    selectDifferentialDataFetched,
    selectRankingData,
    selectRankingFetched,
    selectAnalyticsPerCategory,
    selectOutcomeData,
    selectScorecardDataFetched,
    selectFlagpoleData,
    selectFlagpoleFetched,
    selectScorecardDropdown,
    selectFilterParam,
    selectScorecardError,
    selectRankingError,
    selectFlagpoleError,
    selectDifferentialError,
    selectFiltersMapping,
    selectFiltersMappingFetched,
    selectDefaultFilters,
    selectDefaultYear,
    selectRange,
    selectMedianData,
    selectMedianDataFetched,
    selectShowQuartileBtn,
    selectShowMedianBtn,
    selectMedianError,
    selectCustomBmList,
    selectCustomBmListFetched,
    selectCustomBmSurveyList,
    selectSaveCustomBmDraft,
    selectCustomBmDetail,
    selectCreateCustomBmForm,
    selectCreateCustomBmFilter,
    selectCheckNameMsg,
    selectIsNameAvailable,
    selectSurveyListLoading,
    selectCustomBmDefaultFilter,
    selectActionCode,
    selectApprovalRequestList,
    selectApprovalRequestListFetched,
    selectApprovalRejectList,
    selectApprovalRejectListFetched,
    selectApprovedList,
    selectApprovedListFetched,
    selectEditCustomBmForm,
    selectBmExist,
    selectExistingBm,
    selectDefaultRealTimeYear,
    selectRealtimeTotalData,
    selectRealtimeSurveyList,
    selectRealtimeApproveList,
    selectRealTimeApproveListFetched,
    selectRealTimeRejectList,
    selectRealTimeRejectListFetched,
    selectSecondPopupVisible,
    selectRealtimeTotalDataFetched,
    selectRealtimeSurveyListFetched,
    selectRealtimeFiltersApplied,
    selectRealtimeFiltersData,
    selectRealtimeDafaultFiltersData,
    selectRealtimeFiltersParams,
    selectRealtimeFilterDataFetched,
    selectRealtimeRefreshMeta,
};
