import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import c from 'classnames';
// import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// import Lottie from 'react-lottie';
// import { getStaticText } from '../../../../utils/constants';
// import { getColor, getNegColor } from '../../../../utils/functions';
// import animationData from '../../assets/lottie.json';
// import FilterSvg from '../../assets/Filter.svg';
// import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
// import BulletList from '../../assets/BulletList.svg';
// import Down from '../../assets/Down.svg';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import Resurvey from './Resurvey';
import AuthStore from '../../../../common/AuthStore';
import { getStaticText, RESURVEY_EXPORT_PARAMS } from '../../../../utils/constants';
import { getBenchmarkString, getFilterArray, getOutputFilter, updateFilterParams } from '../../../../utils/functions';
import FocusArea from '../../assets/FocusArea.svg';
import viewIcon from '../../../Reports/assets/viewIcon.svg';
import Questions from './Questions';
import FilterBar from '../../../../components/FilterBar';

function QuestionsBoard({
    singleQuestion,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    // resurveyModal,
    // setReSurveyModal,
    getEmpExpResurveyData,
    empExpResurveyData,
    empExpResurveyDataFetched,
    projects,
    projectsFetched,
    resetEmpExpResDataFetched,
    getDemographics,
    getBenchmarks,
    apiParams,
    filters,
    setFilters,
    updateFilters,
    setdwnld,
    getReport,
}) {
    const {
        lang,
        ohid: currentOhid,
        benchmark = [],
        year: oldYear = '',
        report_type: surveyType,
        emp_exp_resurvey_settings,
        emp_exp_resurvey_custom_delta,
    } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    // const { lang, report_type: surveyType = '' } = defaultSettings || {};
    // const staticText = getStaticText(lang);
    // const { IND_EXP_SCORE_CLASSIFICATION, QUES_LEGEND_TYPE, SITE_TEXT } = staticText;
    // const {
    //     SINGLE_QUESTIONS_LEGEND,
    //     IND_QUESTIONS_QUES,
    //     NEGATIVE_PRACTICES,
    //     RESPONSE_PERCENT,
    //     EE_PERCENT_FAVORABLE,
    //     EE_PERCENTILE,
    //     NOTE,
    //     IND_EXP_QUESTION_NOTE,
    //     IND_EXP_LINKS,
    //     FILTERS,
    //     DOWNLOAD,
    //     NUMBER_OF_RESPONDENTS_N,
    //     EMPLYEE_EXPEIENCE,
    //     IND_QUES_LEGEND,
    //     FREQUENCY_LEVEL_PERCENT,
    // } = SITE_TEXT || {};
    // const [selectedTab, setSelectedTab] = useState(0);
    // const { SINGLE_QUESTIONS_LEGEND, IND_QUESTIONS_QUES, NEGATIVE_PRACTICES, RESPONSE_PERCENT } = SITE_TEXT[lang] || {};
    // const [currentView, setReport] = useState(0);
    const [resurveyModal, setReSurveyModal] = useState(false);
    const [selectedIds, updateIds] = useState([]);
    const [currentView, setReport] = useState(0);
    const [reSurveyFilter, updateFilterView] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showCompAll, setShowCompAll] = useState(false);
    const { YEAR, VIEW_LESS, VIEW_MORE, COMPARISON_BUBBLES } = SITE_TEXT;
    const projectsInUse = projects.filter(({ ohid }) => ohid !== currentOhid);
    const callReSurveyApi = (currentIds, indexToUse = 0) => {
        if (JSON.stringify(selectedIds) !== JSON.stringify(currentIds)) {
            updateIds(currentIds);
            const { ohid: newId, year: newYear } = projectsInUse[currentIds[indexToUse]];
            getDemographics({ ohid: newId, lang: parseInt(lang, 10) }, indexToUse + 1);
            getBenchmarks({ ohid: newId, year: newYear, lang }, indexToUse + 1, benchmark);
            AuthStore[`resurveyName${indexToUse + 1}`] = `${newId} (${newYear})`;
            resetEmpExpResDataFetched(false);
        }
    };
    const maxItemsToShow = 6;
    const { year: prevYear, ohid: newId = '' } = projectsInUse[selectedIds[0]] || {};
    const projectInFilter = [`${currentOhid} - ${oldYear}`, `${newId} - ${prevYear}`];
    const { qbyq = {} } = empExpResurveyData || {};
    const { size: sizeToUse = [], n_respondents: surveyRespondents = [], n_survey: surveys = [] } = qbyq;

    const setResFilters = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, oldBench);
        const filtersToSend = currentView < 3 ? newFilters : [newFilters[0]];
        updateFilters({ filters: filtersToSend, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
        resetEmpExpResDataFetched(false);
        // if (currentView < 3) {
        //     resetReSurveyDataFetched(false);
        // }
    };

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const toggleCompShowAll = () => {
        setShowCompAll(!showCompAll);
    };

    const handleAllFilterData = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: filBenchmark = [] } = filtersHere[0];
        const allList = { benchmarks: filBenchmark, demographics: newDemographic };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 0);
        }
    };

    const handleAllCompFilter = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: compBenchmark = [] } = filtersHere[0];
        const allList = { benchmarks: compBenchmark, demographics: newDemographic };
        const newList = [...filters.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 1);
        }
    };

    const appliedResurveyFiltersInfo = (years = [oldYear, prevYear]) => {
        const { demographics = [] } = filters[0] || {};
        // const label = getBenchmarkName(bm);
        const { filters: filterApplied = [] } = apiParams;
        const { benchmarks: compBenchmarks = [], demographics: compDemographic = [] } = filters[1] || {};
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks) || {};
        // const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        // const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);

        return (
            <div className="filterAppliedContent">
                <div className="filterDemo">
                    <div className="heading">Report :</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{years[0]}</div>
                    </div>
                    {newItem !== null &&
                        newItem
                            .slice(0, showAll ? newItem.length : maxItemsToShow)
                            .map(({ parentK, childKey, parentCode, childCode }) => {
                                return (
                                    <div className="filterContent">
                                        <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                            <div
                                                key={parentCode}
                                                className="filterHighlight filterKey"
                                            >{`${childKey}`}</div>
                                        </LightTooltip>
                                        <img
                                            src={FocusArea}
                                            alt=""
                                            className="filterImg"
                                            onClick={() => {
                                                handleAllFilterData(parentCode, childCode, demographics, filters);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    {(newItem.length >= maxItemsToShow || showAll) && (
                        <div onClick={toggleShowAll}>
                            {showAll ? (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_LESS}</div>
                                </div>
                            ) : (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_MORE}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="filterDemo">
                    <div className="heading">Comparison :</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{years[1]}</div>
                    </div>
                    {newItemComp !== null &&
                        newItemComp
                            .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                            .map(({ parentK, childKey, parentCode, childCode }) => {
                                return (
                                    <div className="filterContent">
                                        <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                            <div
                                                key={parentCode}
                                                className="filterHighlight filterKey"
                                            >{`${childKey}`}</div>
                                        </LightTooltip>
                                        <img
                                            src={FocusArea}
                                            alt=""
                                            className="filterImg"
                                            onClick={() => {
                                                handleAllCompFilter(parentCode, childCode, compDemographic, filters);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                        <div onClick={toggleCompShowAll}>
                            {showCompAll ? (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_LESS}</div>
                                </div>
                            ) : (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_MORE}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getResurveyParams = newFilters => {
        // const selectedIds = [ohid, newOhid];
        // const resOhid = `${selectedIds.join(',')}`;
        // const compare_ids = [`${ohid}-${newOhid}`];
        // const selectedOhiId = selectedIds.map(indexId => projects[indexId].ohid);
        const ohid = `${currentOhid},${newId}`;
        const compare_ids = [`${currentOhid}-${newId}`];
        const filtersArray = [];
        const benchmarksArray = [];
        const { key: comparison_type = '1' } =
            COMPARISON_BUBBLES.find(
                ({ list_key }) => list_key.toLowerCase() === emp_exp_resurvey_settings.toLowerCase()
            ) || {};
        newFilters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
            let benchMarkYear = oldYear;
            let preString = '';
            let endString = '';

            if (indexToUse) {
                const projectIndex = selectedIds[indexToUse - 1];
                if (isNaN(Number(projectIndex))) return;
                const { year } = projects[projectIndex];
                benchMarkYear = year;
                preString = indexToUse === 1 ? '||' : '||||';
                endString = indexToUse === 1 && selectedIds.length === 2 ? '||' : '';
            } else {
                endString = selectedIds.length === 2 ? '||||' : '||';
            }
            const filtersObtained = getFilterArray(newD, preString, endString);
            filtersObtained.forEach(result => {
                filtersArray.push(result);
            });
            if (newB.length) {
                benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
            }
        });
        return {
            ohid,
            // filters: filtersArray,
            filters: filtersArray,
            benchmark_str: benchmarksArray.join(','),
            compare_ids,
            report_type: surveyType,
            comparison_type,
            lang: parseInt(lang, 10),
        };
    };

    const getData = newFilters => {
        const RESURVEY_PARAMS = getResurveyParams(newFilters);
        getEmpExpResurveyData(RESURVEY_PARAMS);
    };

    const pptDownloadForResurvey = () => {
        const { benchmark_str } = getResurveyParams(filters);
        const { bm_filters } = apiParams;
        const { demographic, report_name: rName, resurvey_custom_delta, threshold, report_id, year } = defaultSettings;
        const { key: ee_Resurvey_SigVsQuartile = '' } =
            COMPARISON_BUBBLES.find(
                ({ list_key }) => list_key.toLowerCase() === emp_exp_resurvey_settings.toLowerCase()
            ) || {};
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const resurveyParams = {
            ...RESURVEY_EXPORT_PARAMS,
            report_name: rName,
            ...addOns,
            options: {
                ...RESURVEY_EXPORT_PARAMS.options,
                survey_names: `${currentOhid}(${year})$#$${newId}(${oldYear})`,
                ee_Resurvey_SigVsQuartile,
                Resurvey_SigVsQuartile: ee_Resurvey_SigVsQuartile,
                resurvey_custom_delta,
                Resurvey_years: prevYear,
                ee_resurvey_custom_delta: emp_exp_resurvey_custom_delta,
            },
            bm_filters,
            benchmarks: benchmark_str,
            threshold,
            report_id,
            year: parseInt(year, 10),
        };
        const valueToSend = {
            ...resurveyParams,
            ...getResurveyParams(filters),
            single_page_meta: {
                checkme: ['Employee_Experience_qbyq_resurvey'],
                name: 'Employee_Experience_Questions_Resurvey',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const getPage = () => {
        let page;
        switch (currentView) {
            case 0:
                page = (
                    <Questions
                        singleQuestion={singleQuestion}
                        defaultSettings={defaultSettings}
                        apiLoadingCount={apiLoadingCount}
                        errorInfo={errorInfo}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        appliedFiltersInfo={appliedFiltersInfo}
                        toggleFilterView={toggleFilterView}
                        pptDownload={pptDownload}
                        activeResetFilter={activeResetFilter}
                        setReport={setReport}
                        resurveyModal={resurveyModal}
                        setReSurveyModal={setReSurveyModal}
                        callReSurveyApi={callReSurveyApi}
                        selectedIds={selectedIds}
                        projects={projectsInUse}
                        projectsFetched={projectsFetched}
                    />
                );
                break;
            case 1:
                page = (
                    <Resurvey
                        singleQuestion={singleQuestion}
                        defaultSettings={defaultSettings}
                        apiLoadingCount={apiLoadingCount}
                        errorInfo={errorInfo}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        appliedFiltersInfo={appliedResurveyFiltersInfo}
                        toggleFilterView={updateFilterView}
                        pptDownload={pptDownloadForResurvey}
                        activeResetFilter={activeResetFilter}
                        setReport={setReport}
                        years={[oldYear, prevYear]}
                        empExpResurveyData={empExpResurveyData}
                    />
                );
                break;
            default:
                page = <Fragment />;
        }
        return page;
    };

    useEffect(() => {
        if (!empExpResurveyDataFetched && !apiLoadingCount && currentView !== 0) {
            const allDataReceived = filters.filter(({ benchmarks: cBen, demographics: cDen }) => cBen && cDen).length;
            if (allDataReceived === filters.length) {
                getData(filters);
            }
        }
        // eslint-disable-next-line
    }, [empExpResurveyDataFetched, filters, apiLoadingCount]);

    return (
        <Fragment>
            {getPage()}
            {reSurveyFilter && (
                <FilterBar
                    activeResetFilter={activeResetFilter}
                    size={sizeToUse}
                    surveyRespondents={surveyRespondents}
                    surveys={surveys}
                    filters={filters}
                    projectInFilter={projectInFilter}
                    currentView={currentView}
                    showBenchmarks
                    closeFilters={updateFilterView}
                    setFilters={setResFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                    years={[oldYear, prevYear]}
                />
            )}
        </Fragment>
    );
}

QuestionsBoard.propTypes = {
    singleQuestion: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    getEmpExpResurveyData: PropTypes.func.isRequired,
    empExpResurveyData: PropTypes.object.isRequired,
    empExpResurveyDataFetched: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    resetEmpExpResDataFetched: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    apiParams: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    setdwnld: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
};

export default QuestionsBoard;
