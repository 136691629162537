import { deleteWebApi, fetchWebApi, postWebApi, putWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getDefaultYearUrl = () => {
    const { gsodBaseUrl, defaultBmYrPath } = endpoints;
    const url = `${gsodBaseUrl}${defaultBmYrPath}`;
    return fetchWebApi(url).request;
};

// export const getFilterDataUrl = year => {

//     const { gsodBaseUrl, filterDataPath } = endpoints;
//     const url = `${gsodBaseUrl}${filterDataPath}?year=${year}`;
//     return fetchWebApi(url).request;
// };

export const getFilterDataUrl = options => {
    const { year, role } = options;
    const { gsodBaseUrl, filterDataPath } = endpoints;
    const url = `${gsodBaseUrl}${filterDataPath}?year=${year}`;
    return fetchWebApi(url).request;
};

export const getFilterMappingUrl = year => {
    const { gsodBaseUrl, filterMappingPath } = endpoints;
    const url = `${gsodBaseUrl}${filterMappingPath}?year=${year}`;
    return fetchWebApi(url).request;
};

export const getBmFilterDataUrl = () => {
    const { gsodBaseUrl, bmFilterDataPath } = endpoints;
    const url = `${gsodBaseUrl}${bmFilterDataPath}`;
    return fetchWebApi(url).request;
};

export const getAnalyticsDataUrl = options => {
    const { gsodBaseUrl, analyticsDataPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsDataPath}?filters=${options}`;
    return fetchWebApi(url).request;
};

export const getAnalyticsRankingUrl = options => {
    const { gsodBaseUrl, analyticsRankingPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsRankingPath}?rank_type=overall&filters=${options}`;
    return fetchWebApi(url).request;
};

export const getDifferentialUrl = options => {
    const { filters = '', remove_threshold = '' } = options;
    const { gsodBaseUrl, analyticsRankingPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsRankingPath}?rank_type=differentiator&filters=${filters}&skip_threshold=${remove_threshold}`;
    return fetchWebApi(url).request;
};

export const getFlagpoleUrl = options => {
    const { gsodBaseUrl, analyticsScorecardPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsScorecardPath}?analysis_type=flagpole&filters=${options}`;
    return fetchWebApi(url).request;
};

export const getAnalyticsPerCategoryUrl = options => {
    const { gsodBaseUrl, analyticsPerCategoryPath } = endpoints;
    const { filters = '', filter_id = '' } = options;
    const url = `${gsodBaseUrl}${analyticsPerCategoryPath}?filters=${filters}&filter_id=${filter_id}`;
    return fetchWebApi(url).request;
};

export const getScorecardUrl = options => {
    const { gsodBaseUrl, analyticsScorecardPath } = endpoints;
    const { filters = '' } = options || {};
    const url = `${gsodBaseUrl}${analyticsScorecardPath}?analysis_type=scorecard&filters=${filters}`;
    return fetchWebApi(url).request;
};

export const getMedianUrl = options => {
    const { filters = '', remove_threshold = '' } = options;
    const { gsodBaseUrl, analyticsRankingPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsRankingPath}?rank_type=mediansplit&filters=${filters}&skip_threshold=${remove_threshold}`;
    return fetchWebApi(url).request;
};

export const getCustomBmSurveyListUrl = options => {
    const { gsodBaseUrl, customBmSurveyListPath } = endpoints;
    const url = `${gsodBaseUrl}${customBmSurveyListPath}?filters=${options}`;
    return fetchWebApi(url).request;
};

export const getCustomBmListUrl = options => {
    const { gsodBaseUrl, customBmListPath } = endpoints;
    const url = `${gsodBaseUrl}${customBmListPath}`;
    return postWebApi(url, options).request;
};

export const saveCustomBmDraft = options => {
    const { gsodBaseUrl, customBmPath } = endpoints;
    const url = `${gsodBaseUrl}${customBmPath}`;
    return postWebApi(url, options).request;
};

export const getCustomBmDetails = options => {
    const { gsodBaseUrl, customBmDetail } = endpoints;
    const url = `${gsodBaseUrl}${customBmDetail}/${options}`;
    return fetchWebApi(url).request;
};

export const deleteCustomBenchmark = id => {
    const { gsodBaseUrl, deleteCustomBm } = endpoints;
    const url = `${gsodBaseUrl}${deleteCustomBm}/${id}`;
    return deleteWebApi(url).request;
};

export const updateCustomBenchmark = options => {
    const { id, payload } = options;
    const { gsodBaseUrl, editCustomBm } = endpoints;
    const url = `${gsodBaseUrl}${editCustomBm}/${id}`;
    return putWebApi(url, payload).request;
};

export const checkNameExist = options => {
    const { gsodBaseUrl, checkCustomBmNameExist } = endpoints;
    const url = `${gsodBaseUrl}${checkCustomBmNameExist}`;
    return postWebApi(url, options).request;
};

export const sendCustomBmRequest = options => {
    const { id, payload } = options;
    const { gsodBaseUrl, sendCustomBmReq } = endpoints;
    const url = `${gsodBaseUrl}${sendCustomBmReq}${id}`;
    return putWebApi(url, payload).request;
};

export const getSurveyList = options => {
    const { q = '', year = '' } = options || {};
    const { apiBasePath, searchOhiIds } = endpoints;
    const url = `${apiBasePath}${searchOhiIds}?year=${year}&q=${q}`;
    return fetchWebApi(url).request;
};

export const approvalRequest = options => {
    const { gsodBaseUrl, requestedBenchmark } = endpoints;
    const { status, filters, page, limit } = options || {};
    const url = `${gsodBaseUrl}${requestedBenchmark}?status=${status}&filters=${filters}&limit=${limit}&page=${page}`;
    return postWebApi(url, {}).request;
};

export const approveRequest = id => {
    const { gsodBaseUrl, approveReq } = endpoints;
    const url = `${gsodBaseUrl}${approveReq}${id}`;
    return putWebApi(url, {}).request;
};

export const rejectRequest = id => {
    const { gsodBaseUrl, rejectReq } = endpoints;
    const url = `${gsodBaseUrl}${rejectReq}${id}`;
    return putWebApi(url, {}).request;
};

export const duplicateCustomBm = id => {
    const { gsodBaseUrl, duplicateBm } = endpoints;
    const url = `${gsodBaseUrl}${duplicateBm}${id}`;
    return putWebApi(url, {}).request;
};

export const checkValidation = options => {
    const { gsodBaseUrl, preApproveValidation } = endpoints;
    const url = `${gsodBaseUrl}${preApproveValidation}${options}`;
    return fetchWebApi(url).request;
};

export const getRealtimeYearUrl = () => {
    const { gsodBaseUrl, realTimeYearPath } = endpoints;
    const url = `${gsodBaseUrl}${realTimeYearPath}`;
    return fetchWebApi(url).request;
};

export const getRealtimeAnalyticsUrl = options => {
    const { gsodBaseUrl, realTimeAnalyticsPath } = endpoints;
    const url = `${gsodBaseUrl}${realTimeAnalyticsPath}?filters=${options}`;
    return fetchWebApi(url).request;
};

export const getRealtimeSurveyListUrl = options => {
    const { gsodBaseUrl, realTimeAnalyticsList } = endpoints;
    const url = `${gsodBaseUrl}${realTimeAnalyticsList}?filters=${options}`;
    return fetchWebApi(url).request;
};

export const realtimeApproveRequestUrl = options => {
    const { id, year } = options;
    const { gsodBaseUrl, realTimeApprovePath } = endpoints;
    const url = `${gsodBaseUrl}${realTimeApprovePath}?year=${year}`;
    return putWebApi(url, id).request;
};

export const realtimeRejectUrl = options => {
    const { id, year } = options;
    const { gsodBaseUrl, realTimeRejectPath } = endpoints;
    const url = `${gsodBaseUrl}${realTimeRejectPath}?year=${year}`;
    return putWebApi(url, id).request;
};

export const getRealtimeFiltersUrl = year => {
    const { gsodBaseUrl, reatimeFiltersPath } = endpoints;
    const url = `${gsodBaseUrl}${reatimeFiltersPath}?year=${year}`;
    return fetchWebApi(url).request;
};

export const putRefreshMetaUrl = options => {
    const { id, year } = options;
    const { gsodBaseUrl, refreshRealTimeMeta } = endpoints;
    const url = `${gsodBaseUrl}${refreshRealTimeMeta}?year=${year}`;
    return putWebApi(url, id).request;
};
