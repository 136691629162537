import React from 'react';
// import { Dialog } from '@material-ui/core';
import c from 'classnames';
import PropTypes from 'prop-types';
import CloseIcon from '../../../assets/Close.svg';
import './index.scss';

const ConfirmModal = ({ closeAction, handleAcceptAction, message, success, failed }) => {
    return (
        <div className="popup">
            <div className="popupContent">
                <div className="realtimeCloseIcon">
                    <img src={CloseIcon} alt="" className="closeIcon" onClick={closeAction} />
                </div>
                <h2>Refresh Meta Information</h2>
                <p className={c({ success, failed })}>{message}</p>
                <div className="realtimePopupButton">
                    <button type="button" onClick={handleAcceptAction} className="approveBtn">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

ConfirmModal.propTypes = {
    closeAction: PropTypes.func.isRequired,
    handleAcceptAction: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
};

export default ConfirmModal;
