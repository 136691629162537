import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { cloneDeep } from 'lodash';

import FilterOptions from './FilterOptions';
import { getStaticText } from '../../utils/constants';
import '../FilterBar/index.scss';

function FilterContent({
    filters,
    activeApplyButton,
    filtersApplied,
    setDemoActive,
    updateFilterState,
    updateCurrentFilter,
    showHeader,
    resetFilters,
    activeProject,
    defaultSettings,
    defaultFilters,
    filtersMapping,
    updateFilters,
    isFiltersApplied,
    range,
    setRange,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { APPLY_FILTERS, RESET_FILTERS } = SITE_TEXT || {};
    const [activeFilter, updateView] = useState(null);
    const setBackOldView = (updateRedux = false) => {
        if (updateRedux) {
            updateFilterState(activeProject, [], true);
        }
        updateView(null);
    };

    const unmappedDemo = filterObj => {
        const filterItem = cloneDeep(filterObj);
        const selectedItems = filterItem
            .filter(item => item.filter_id === 'year')
            .flatMap(item => item.filter_items)
            .filter(item => item.isSelected)
            .map(item => item.filter_item_name);

        if (selectedItems[0] === '2023') {
            const filterYearIndex = filterItem.findIndex(({ filter_id }) => filter_id === 'survey_year');
            const { filter_items = [] } = filterItem[filterYearIndex];
            filter_items.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                const newItem = parseInt(filter_item_name, 10);
                if (newItem < 2023 && newItem >= 2018) {
                    filterItem[filterYearIndex].filter_items[i].allowSelect = true;
                } else {
                    filterItem[filterYearIndex].filter_items[i].allowSelect = false;
                }
            });
            filterItem[filterYearIndex].isSurveyDefault = true;
        }

        // new
        if (selectedItems[0] === '2025') {
            const filterYearIndex = filterItem.findIndex(({ filter_id }) => filter_id === 'survey_year');
            const { filter_items = [] } = filterItem[filterYearIndex];
            filter_items.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                const newItem = parseInt(filter_item_name, 10);
                if (newItem < 2024 && newItem >= 2018) {
                    filterItem[filterYearIndex].filter_items[i].allowSelect = true;
                } else {
                    filterItem[filterYearIndex].filter_items[i].allowSelect = false;
                }
            });
            filterItem[filterYearIndex].isSurveyDefault = true;
        }

        if (selectedItems[0] === '2024') {
            const filterYearIndex = filterItem.findIndex(({ filter_id }) => filter_id === 'survey_year');
            const { filter_items = [] } = filterItem[filterYearIndex];
            filter_items.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                const newItem = parseInt(filter_item_name, 10);
                if (newItem < 2024 && newItem >= 2019) {
                    filterItem[filterYearIndex].filter_items[i].allowSelect = true;
                } else {
                    filterItem[filterYearIndex].filter_items[i].allowSelect = false;
                }
            });
            filterItem[filterYearIndex].isSurveyDefault = true;
        }
        const newList = cloneDeep(filterItem);

        return newList;
    };

    const removeUnmappedDemo = filterObj => {
        const listedItem = unmappedDemo(filterObj);
        const filterItem = cloneDeep(listedItem);
        const newFilterItem = [];
        let mappedItem = [];
        const filteredObj = {
            '2': [],
            '3': [],
            '4': [],
            '5': [],
            '6': [],
            '7': [],
            '9': [],
            quartile: [],
            survey_year: [],
            totalcomplete: [],
        };
        const noneSelected = !filterItem
            .filter(
                ({ filter_id }) => filter_id !== 'year' && filter_id !== 'survey_year' && filter_id !== 'totalcomplete'
            )
            .filter(({ isSelected = false }) => isSelected).length;
        if (noneSelected) {
            return listedItem;
        }
        filterItem
            .filter(
                ({ filter_id }) => filter_id !== 'year' && filter_id !== 'survey_year' && filter_id !== 'totalcomplete'
            )
            .forEach(_item => {
                const { filter_items = [], isSelected = false, filter_id } = _item;
                if (isSelected) {
                    const selectedArr = [];
                    filter_items.forEach(({ filter_item_id, isSelected: selectedChild = false }) => {
                        if (selectedChild) {
                            selectedArr.push(filter_item_id);
                        }
                    });
                    mappedItem = filtersMapping.filter(({ [filter_id]: childkey }) =>
                        selectedArr.includes(parseInt(childkey, 10))
                    );
                }
            });
        mappedItem.forEach(item => {
            Object.keys(item).forEach(elem => {
                if (!filteredObj[elem].includes(item[elem])) {
                    filteredObj[elem].push(parseInt(item[elem], 10));
                }
            });
        });
        Object.keys(filteredObj).forEach(elem => {
            filteredObj[elem] = [...new Set(filteredObj[elem])].sort((a, b) => a - b);
        });
        filterItem.forEach(_item => {
            const { filter_items = [], filter_id } = _item;
            const filterItemShow = filter_id === 'survey_year' && Object.values(filteredObj).length < 0;
            if (filter_id === 'year' || filterItemShow) {
                newFilterItem.push(_item);
            } else {
                const childFilters = filter_items.filter(({ filter_item_id }) =>
                    filteredObj[filter_id].includes(filter_item_id)
                );
                newFilterItem.push({
                    ..._item,
                    filter_items: childFilters,
                });
            }
        });
        return newFilterItem;
    };

    useEffect(() => {
        const list = removeUnmappedDemo(filters);
        if (!activeProject) {
            updateFilters(filters);
        } else {
            updateFilters(list);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            {showHeader && !activeFilter && (
                <div className="projectTextContent">
                    <h3 className="projectName" onClick={() => updateCurrentFilter(null)}>
                        <ArrowBack />
                        {showHeader}
                    </h3>
                </div>
            )}
            {!isNaN(activeProject) && (
                <FilterOptions
                    defaultSettings={defaultSettings}
                    activeFilter={[filters[activeProject]]}
                    filters={filters}
                    setDemoActive={setDemoActive}
                    setBackOldView={setBackOldView}
                    updateFilters={newF => updateFilterState(activeProject, newF)}
                    showHeader={false}
                    activeProject={activeProject}
                    defaultFilters={defaultFilters}
                    setRange={setRange}
                    range={range}
                />
            )}
            {!isNaN(activeProject) && (
                <div className="buttonContent clearfix">
                    <Button
                        classes={{ root: c({ active: filtersApplied || isFiltersApplied }) }}
                        onClick={resetFilters}
                    >
                        {RESET_FILTERS}
                    </Button>
                    <Button classes={{ root: c({ active: activeApplyButton }) }} onClick={() => setBackOldView(true)}>
                        {APPLY_FILTERS}
                    </Button>
                </div>
            )}
        </Fragment>
    );
}

FilterContent.propTypes = {
    activeProject: PropTypes.number.isRequired,
    showHeader: PropTypes.string.isRequired,
    activeApplyButton: PropTypes.bool.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    updateFilterState: PropTypes.func.isRequired,
    setDemoActive: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    updateCurrentFilter: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    filtersMapping: PropTypes.array.isRequired,
    updateFilters: PropTypes.func.isRequired,
    isFiltersApplied: PropTypes.bool.isRequired,
    range: PropTypes.array.isRequired,
    setRange: PropTypes.func.isRequired,
};

export default FilterContent;
