import React, { Fragment, useState } from 'react';
// import c from 'classnames';
import PropTypes from 'prop-types';
// import { Tooltip } from '@material-ui/core';

import { cloneDeep } from 'lodash';
import { Tooltip, withStyles } from '@material-ui/core';
import { getStaticText, RESURVEY_EXPORT_PARAMS } from '../../../../utils/constants';
// import AuthStore from '../../../../common/AuthStore';
import getStaticTextDiagnose from '../../constants';
// import ArrowLeft from '../../assets/ArrowLeft.svg';
// import ArrowRight from '../../assets/ArrowRight.svg';
// import ArrowUp from '../../assets/ArrowUp.svg';
// import ArrowDown from '../../assets/ArrowDown.svg';
// import { getColor, getNegColor, manipulateHeatmapTitle } from '../../../../utils/functions';
// import colors from '../../../../sass/colors';
import Heatmap from './Heatmap';
import ResurveyHeatmap from './ResurveyHeatmap';
import { getBenchmarkString, getFilterArray, getOutputFilter, updateFilterParams } from '../../../../utils/functions';
import FocusArea from '../../assets/FocusArea.svg';
import viewIcon from '../../../Reports/assets/viewIcon.svg';
import FilterBar from '../../../../components/FilterBar';

function HeatmapBoard({
    filters,
    ieHeatmap,
    ieHeatmapFetched,
    sortIeHeatmap,
    callHeatMap,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    empExpResurveyHeatmapData,
    empExpResurveyHeatmapDataFetched,
    getEmpExpResurveyHeatmapData,
    resurveyModal,
    setReSurveyModal,
    apiParams,
    projects,
    heatMapResurveyInfo,
    getMappedDemographics,
    updateHeatMapInfo,
    getDemographics,
    getBenchmarks,
    addOnTab,
    setAddOnTabs,
    pptDownload,
    toggleFilterView,
    activeResetFilter,
    appliedFiltersInfo,
    selectedDemographics,
    setSelectedDemographic,
    setFilters,
    // empExpResurveyData,
    updateFilters,
    resetEmpExpResDataFetched,
    setdwnld,
    getReport,
    getIeCrosstabData,
    ieCrossTabData,
    getResIesCrosstabData,
    ieResCrossTabData,
    heatMapData,
    heatMapDataFetched,
    getHeatMapOutcome,
    getHeatMapResurvey,
    resurveyOutcomeHeatMapFetched,
    resurveyOutcomeHeatMap,
}) {
    const {
        lang = '1033',
        // report_type: surveyType = '',
        ohid = '',
        // resurvey_settings,
        year: oldYear,
        benchmark,
        emp_exp_resurvey_settings = '',
        emp_exp_resurvey_custom_delta,
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const { QUARTILE_RESURVEY } = getStaticTextDiagnose(lang);
    const { SITE_TEXT } = staticText;
    // const { EE_PERCENT_FAVORABLE, EE_PERCENTILE, NOTE, IND_EXP_QUESTION_NOTE, QUARTILE_RESURVEY } = SITE_TEXT;
    const [currentView, setReport] = useState(0);
    // const { selectedProjectIndex = '' } = heatMapResurveyInfo;
    // const { ohid: newOhid, year: newYear } = projects[selectedProjectIndex] || {};
    // const { demographics = [] } = filters[0] || {};
    // const { header = [], items = [] } = ieHeatmap || {};
    // const [minSliceValue, setMinSliceValue] = useState(SLICE_IE_MIN_VALUE);
    // const [maxSliceValue, setMaxSliceValue] = useState(SLICE_IE_MAX_VALUE);
    // const [showLeftArrow, setShowLeftArrow] = useState(false);
    // const [showRightArrow, setShowRightArrow] = useState(false);
    // const [sortOrder, setSortOrder] = useState({});
    const [selectedIds, updateIds] = useState([]);
    const [reSurveyFilter, updateFilterView] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showCompAll, setShowCompAll] = useState(false);
    const { YEAR, VIEW_LESS, VIEW_MORE, COMPARISON_BUBBLES } = SITE_TEXT;
    // const projectsInUse = useMemo(() => {
    //     return projects.filter(({ ohid: id }) => id !== ohid);
    //     // eslint-disable-next-line
    // }, [ohid, projects]);
    // console.log(selectedIds);
    // const callReSurveyApi = (currentIds, indexToUse = 0) => {
    //     if (JSON.stringify(selectedIds) !== JSON.stringify(currentIds)) {
    //         updateIds(currentIds);
    //         const { ohid: newId } = projectsInUse[currentIds[indexToUse]];
    //         getDemographics({ ohid: newId, lang: parseInt(lang, 10) }, indexToUse + 1);
    //         getBenchmarks({ ohid: newId, year: newYear, lang }, indexToUse + 1, benchmark);
    //         AuthStore[`resurveyName${indexToUse + 1}`] = `${newId} (${newYear})`;
    //         resetEmpExpResDataFetched(false);
    //     }
    // };
    const maxItemsToShow = 6;
    const { year: prevYear, ohid: newId = '' } = projects[selectedIds[0]] || {};
    const projectInFilter = [`${ohid} - ${oldYear}`, `${newId} - ${prevYear}`];
    const { n_respondents: surveyRespondents = [], n_survey: surveys = [] } = empExpResurveyHeatmapData;
    // const { size: sizeToUse = [], n_respondents: surveyRespondents = [], n_survey: surveys = [] } = qbyq;

    const setResFilters = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, oldBench);
        const filtersToSend = currentView < 3 ? newFilters : [newFilters[0]];
        updateFilters({ filters: filtersToSend, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
        resetEmpExpResDataFetched(false);
        // if (currentView < 3) {
        //     resetReSurveyDataFetched(false);
        // }
    };

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const toggleCompShowAll = () => {
        setShowCompAll(!showCompAll);
    };

    const handleAllFilterData = (
        parKey,
        childKey,
        ParDemographic,
        filtersHere,
        updateRedux = true,
        activeFilters = true
    ) => {
        const newDemographic = cloneDeep(ParDemographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks = [] } = filtersHere[0];
        const allList = { demographics: newDemographic, benchmarks };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilters, 0);
        }
    };

    const handleAllCompFilter = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: compBenchmark = [] } = filtersHere[0];
        const allList = { benchmarks: compBenchmark, demographics: newDemographic };
        const newList = [...filters.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 1);
        }
    };

    // useEffect(() => {
    //     if (currentView === 1 && !empExpResurveyHeatmapDataFetched) {
    //         const { heatMapDemo: newDemo } = heatMapResurveyInfo;
    //         const filtersArray = [];
    //         const benchmarksArray = [];
    //         filters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
    //             debugger
    //             const benchMarkYear = indexToUse ? newYear : oldYear;
    //             const preString = indexToUse ? '||' : '';
    //             const endString = indexToUse ? '' : '||';
    //             const filtersObtained = getFilterArray(newD, preString, endString);
    //             filtersObtained.forEach(result => {
    //                 filtersArray.push(result);
    //             });
    //             if (newB.length) {
    //                 benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
    //             }
    //         });
    //         const { lang: lng, year } = apiParams;
    //         getEmpExpResurveyHeatmapData({
    //             ...addOns,
    //             filters: filtersArray,
    //             benchmark_str: benchmarksArray.join(','),
    //             // benchmark_str: '1001:1@2024,1001:1@2023',
    //             ohid: `${ohid},${newOhid}`,
    //             pid: '',
    //             type: 'practice',
    //             demographic: newDemo,
    //             page: 1,
    //             comparison_type: '1',
    //             columns: 50,
    //             lang: lng,
    //             year,
    //         });
    //     }
    // }, [currentView, empExpResurveyHeatmapDataFetched]);

    const appliedResurveyFiltersInfo = (years = [oldYear, prevYear]) => {
        const { demographics = [] } = filters[0] || {};
        // const label = getBenchmarkName(bm);
        const { filters: filterApplied = [] } = apiParams;
        const { benchmarks: compBenchmarks = [], demographics: compDemographic = [] } = filters[1] || {};
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks) || {};
        // const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        // const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);

        return (
            <div className="filterAppliedContent">
                <div className="filterDemo">
                    <div className="heading">Report :</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{years[0]}</div>
                    </div>
                    {newItem !== null &&
                        newItem
                            .slice(0, showAll ? newItem.length : maxItemsToShow)
                            .map(({ parentK, childKey, parentCode, childCode }) => {
                                return (
                                    <div className="filterContent">
                                        <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                            <div
                                                key={parentCode}
                                                className="filterHighlight filterKey"
                                            >{`${childKey}`}</div>
                                        </LightTooltip>
                                        <img
                                            src={FocusArea}
                                            alt=""
                                            className="filterImg"
                                            onClick={() => {
                                                handleAllFilterData(parentCode, childCode, demographics, filters);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    {(newItem.length >= maxItemsToShow || showAll) && (
                        <div onClick={toggleShowAll}>
                            {showAll ? (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_LESS}</div>
                                </div>
                            ) : (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_MORE}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="filterDemo">
                    <div className="heading">Comparison :</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{years[1]}</div>
                    </div>
                    {newItemComp !== null &&
                        newItemComp
                            .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                            .map(({ parentK, childKey, parentCode, childCode }) => {
                                return (
                                    <div className="filterContent">
                                        <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                            <div
                                                key={parentCode}
                                                className="filterHighlight filterKey"
                                            >{`${childKey}`}</div>
                                        </LightTooltip>
                                        <img
                                            src={FocusArea}
                                            alt=""
                                            className="filterImg"
                                            onClick={() => {
                                                handleAllCompFilter(parentCode, childCode, compDemographic, filters);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                        <div onClick={toggleCompShowAll}>
                            {showCompAll ? (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_LESS}</div>
                                </div>
                            ) : (
                                <div className="viewIconContent">
                                    <img src={viewIcon} alt="" className="viewImg" />
                                    <div className="viewText">{VIEW_MORE}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getResurveyParams = newFilters => {
        const { report_type } = defaultSettings;
        const selectedOhIds = [ohid, newId];
        const resOhid = `${selectedOhIds.join(',')}`;
        const compare_ids = [`${ohid}-${newId}`];
        const filtersArray = [];
        const benchmarksArray = [];
        newFilters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
            let benchMarkYear = oldYear;
            let preString = '';
            let endString = '';

            if (indexToUse) {
                const projectIndex = selectedOhIds[indexToUse - 1];
                if (isNaN(Number(projectIndex))) return;
                const { year } = projects[projectIndex];
                benchMarkYear = year;
                preString = indexToUse === 1 ? '||' : '||||';
                endString = indexToUse === 1 && selectedOhIds.length === 2 ? '||' : '';
            } else {
                endString = selectedOhIds.length === 2 ? '||||' : '||';
            }
            const filtersObtained = getFilterArray(newD, preString, endString);
            filtersObtained.forEach(result => {
                filtersArray.push(result);
            });
            if (newB.length) {
                benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
            }
        });
        return {
            ohid: resOhid,
            // filters: filtersArray,
            filters: filtersArray,
            benchmark_str: benchmarksArray.join(','),
            compare_ids,
            report_type,
            comparison_type: emp_exp_resurvey_settings.toLowerCase() === QUARTILE_RESURVEY ? 2 : 1,
            lang: parseInt(lang, 10),
        };
    };

    const getHeatmapDemographic = () => {
        const { heatMapDemo = '' } = heatMapResurveyInfo;
        const { demographics = [] } = filters[0] || {};
        const map = new Map();
        demographics.forEach(({ code, label }) => {
            if (heatMapDemo === code) {
                map.set(code, label);
            }
        });
        return Object.fromEntries(map);
    };

    const resurveyPptDownload = (sName, sVersion) => {
        const { benchmark_str, ohid: ohidParam } = getResurveyParams(filters);
        const { bm_filters } = apiParams;
        const {
            year = '',
            threshold,
            report_name: rName = '',
            resurvey_custom_delta = '',
            report_id = '',
            demographic = '',
        } = defaultSettings;
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { key: ee_Resurvey_SigVsQuartile = '' } =
            COMPARISON_BUBBLES.find(
                ({ list_key }) => list_key.toLowerCase() === emp_exp_resurvey_settings.toLowerCase()
            ) || {};
        const resurveyParams = {
            ...RESURVEY_EXPORT_PARAMS,
            ...addOns,
            options: {
                ...RESURVEY_EXPORT_PARAMS.options,
                survey_names: `${ohidParam.split(',')[0]}$#$${ohidParam.split(',')[1]}`,
                ee_Resurvey_SigVsQuartile,
                Resurvey_SigVsQuartile: ee_Resurvey_SigVsQuartile,
                resurvey_custom_delta,
                Resurvey_years: prevYear,
                ee_resurvey_custom_delta: emp_exp_resurvey_custom_delta,
            },
            bm_filters,
            benchmarks: benchmark_str,
            report_id: `${report_id}`,
            threshold,
            report_name: rName || 'Organisation',
            practicePptVersion: sVersion,
            outcomePptVersion: sVersion,
            employee_experience_demographic: getHeatmapDemographic(),
            heatmap_demographic: getHeatmapDemographic(),
            year: parseInt(year, 10),
        };
        const valueToSend = {
            ...resurveyParams,
            ...getResurveyParams(filters),
            single_page_meta: {
                checkme: ['Employee_Experience_heatmap_resurvey', 'Employee_Experience_eNPS_demographic_resurvey'],
                name: 'Employee_Experience_Heatmap_Resurvey',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const getPage = () => {
        if (currentView === 1) {
            return (
                <ResurveyHeatmap
                    filters={filters}
                    ieHeatmap={ieHeatmap}
                    ieHeatmapFetched={empExpResurveyHeatmapDataFetched}
                    sortIeHeatmap={sortIeHeatmap}
                    callHeatMap={callHeatMap}
                    defaultSettings={defaultSettings}
                    apiLoadingCount={apiLoadingCount}
                    errorInfo={errorInfo}
                    apiParams={apiParams}
                    addOnTab={addOnTab}
                    setAddOnTabs={setAddOnTabs}
                    toggleFilterView={updateFilterView}
                    appliedFiltersInfo={appliedResurveyFiltersInfo}
                    pptDownload={resurveyPptDownload}
                    empExpResurveyHeatmapData={empExpResurveyHeatmapData}
                    empExpResurveyHeatmapDataFetched={empExpResurveyHeatmapDataFetched}
                    selectedDemographics={selectedDemographics}
                    setSelectedDemographic={setSelectedDemographic}
                    resurveyModal={resurveyModal}
                    setReSurveyModal={setReSurveyModal}
                    setReport={setReport}
                    getEmpExpResurveyHeatmapData={getEmpExpResurveyHeatmapData}
                    heatMapResurveyInfo={heatMapResurveyInfo}
                    projectsInUse={projects}
                    selectedIds={selectedIds}
                    updateHeatMapInfo={updateHeatMapInfo}
                    setdwnld={setdwnld}
                    getReport={getReport}
                    getResIesCrosstabData={getResIesCrosstabData}
                    ieResCrossTabData={ieResCrossTabData}
                    getHeatMapResurvey={getHeatMapResurvey}
                    resurveyOutcomeHeatMapFetched={resurveyOutcomeHeatMapFetched}
                    resurveyOutcomeHeatMap={resurveyOutcomeHeatMap}
                />
            );
        }
        return (
            <Heatmap
                filters={filters}
                ieHeatmap={ieHeatmap}
                ieHeatmapFetched={ieHeatmapFetched}
                sortIeHeatmap={sortIeHeatmap}
                callHeatMap={callHeatMap}
                defaultSettings={defaultSettings}
                apiLoadingCount={apiLoadingCount}
                errorInfo={errorInfo}
                setReSurveyModal={setReSurveyModal}
                heatMapResurveyInfo={heatMapResurveyInfo}
                setReport={setReport}
                resurveyModal={resurveyModal}
                projects={projects}
                getMappedDemographics={getMappedDemographics}
                updateHeatMapInfo={updateHeatMapInfo}
                getDemographics={getDemographics}
                getBenchmarks={getBenchmarks}
                addOnTab={addOnTab}
                setAddOnTabs={setAddOnTabs}
                toggleFilterView={toggleFilterView}
                appliedFiltersInfo={appliedFiltersInfo}
                pptDownload={pptDownload}
                selectedDemographics={selectedDemographics}
                setSelectedDemographic={setSelectedDemographic}
                updateIds={updateIds}
                getIeCrosstabData={getIeCrosstabData}
                apiParams={apiParams}
                ieCrossTabData={ieCrossTabData}
                heatMapData={heatMapData}
                heatMapDataFetched={heatMapDataFetched}
                getHeatMapOutcome={getHeatMapOutcome}
            />
        );
    };
    return (
        <Fragment>
            {/* <div className="heatmapBoard clearfix clearPadding">
                <div className="optionWrapper">
                    <section className={c({ wrapperShadow: headerToUse.length <= 6 }, 'sectionWrapper')}>
                        <div className="outcomeNames">
                            {Object.keys(QUESTIONS_HEATMAP).map(elem => {
                                const { title: name = '', children = [] } = QUESTIONS_HEATMAP[elem];
                                const len = children.includes('N') ? 1 : children.length;
                                return (
                                    <div style={{ height: `${len * 7}vh` }}>
                                        <span>{name}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="contentWrapper clearfix">
                            <div className={c({ noPadding: headerToUse.length <= 6 }, 'mainContent')} id="myHeader">
                                {dataToUse.length ? getHeatMapValues(dataToUse) : null}
                            </div>
                        </div>
                    </section>
                </div>
            </div> */}
            {getPage()}
            {reSurveyFilter && (
                <FilterBar
                    activeResetFilter={activeResetFilter}
                    size={[0, 0]}
                    surveyRespondents={surveyRespondents}
                    surveys={surveys}
                    filters={filters}
                    projectInFilter={projectInFilter}
                    currentView={currentView}
                    showBenchmarks
                    closeFilters={updateFilterView}
                    setFilters={setResFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                    years={[oldYear, prevYear]}
                />
            )}
            {/* {!apiLoadingCount && !errorInfo && (
                <div className="note">
                    <b>{NOTE}:</b>
                    <br />
                    {IND_EXP_QUESTION_NOTE[0]}
                    <br />
                    {IND_EXP_QUESTION_NOTE[1]}
                    <br />
                    {surveyType === 'percent favorable' ? <div>3. {EE_PERCENT_FAVORABLE}</div> : ''}
                    {surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                </div>
            )} */}
        </Fragment>
    );
}

HeatmapBoard.propTypes = {
    filters: PropTypes.array.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    empExpResurveyHeatmapData: PropTypes.object.isRequired,
    empExpResurveyHeatmapDataFetched: PropTypes.bool.isRequired,
    getEmpExpResurveyHeatmapData: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    resurveyModal: PropTypes.bool.isRequired,
    setReSurveyModal: PropTypes.func.isRequired,
    apiParams: PropTypes.object.isRequired,
    heatMapResurveyInfo: PropTypes.object.isRequired,
    getMappedDemographics: PropTypes.func.isRequired,
    updateHeatMapInfo: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    selectedDemographics: PropTypes.bool.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    // empExpResurveyData,
    updateFilters: PropTypes.func.isRequired,
    resetEmpExpResDataFetched: PropTypes.func.isRequired,
    setdwnld: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    getResIesCrosstabData: PropTypes.func.isRequired,
    ieResCrossTabData: PropTypes.object.isRequired,
    getIeCrosstabData: PropTypes.func.isRequired,
    ieCrossTabData: PropTypes.object.isRequired,
    heatMapData: PropTypes.object.isRequired,
    heatMapDataFetched: PropTypes.bool.isRequired,
    getHeatMapOutcome: PropTypes.func.isRequired,
    getHeatMapResurvey: PropTypes.func.isRequired,
    resurveyOutcomeHeatMapFetched: PropTypes.bool.isRequired,
    resurveyOutcomeHeatMap: PropTypes.object.isRequired,
};

export default HeatmapBoard;
